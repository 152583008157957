import { $delete, $fetch, $post, $put } from '@/services/axios';

export function fetch() {
  return $fetch(`operator/menus`);
}

export function privileges() {
  return $fetch(`operator/menu/privileges`);
}

export function sort(menuIds) {
  return $post(`operator/menus/sort`, {
    menuIds: menuIds.toString(),
  });
}

export function validate(menu) {
  return $post(`operator/menu/validate`, menu);
}

export function create(menu) {
  return $put(`operator/menu`, menu);
}

export function update(menu) {
  return $post(`operator/menu/${menu.id}`, menu);
}

export function del(id, version) {
  return $delete(`operator/menu/${id}/${version}`);
}
