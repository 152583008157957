<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-text v-model="model.url" field-name="url" label="url" :error="error.url" :disabled="disabled" />
        <n-field-text v-model="model.icon" field-name="icon" label="icon" :error="error.icon" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
        <n-field-text v-model="model.view" field-name="view" label="view" :error="error.view" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-selection v-model="model.parent" field-name="parent" selection-name="admin.menu" label="parent" :extra-group="0" :error="error.parent" :disabled="disabled" />
        <n-field-mask-enum v-model="model.types" field-name="types" type-name="AdminMenuTypes" label="types" :error="error.types" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-select v-model="model.readPrivilege" field-name="readPrivilege" label="readPrivilege" :options="privilegesOptions" option-name="name" option-value="id" :error="error.readPrivilege" :disabled="disabled" />
        <n-field-select v-model="model.writePrivilege" field-name="writePrivilege" label="writePrivilege" :options="privilegesOptions" option-name="name" option-value="id" :error="error.writePrivilege" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { create, update, validate } from '@/api/operator/operator-menu';

export default NModal.extend({
  name: 'ModalMenu',
  components: { NModal },
  props: {
    privilegesOptions: Array,
  },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
