<template>
  <!-- prettier-ignore -->
  <index-content>
    <template v-if="hasRW" #options>
      <div class="n-button-box">
        <nb-sort v-model="sortable" />
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <div class="table-responsive">
      <table class="table text-center n-table">
        <thead>
        <tr style="background-color: #eee;">
          <th class="col-weight-5">
            <i :class="`all-expand ${ allExpand ? 'fas fa-minus' : 'fas fa-plus' }`" @click="changeAllExpand"></i>
          </th>
          <th class="col-weight-8">{{ $t('label.id') }}</th>
          <th class="col-weight-8">{{ $t('label.icon') }}</th>
          <th class="col-weight-19">{{ $t('label.name') }}</th>
          <th class="col-weight-19">{{ $t('label.url') }}</th>
          <th class="col-weight-16">{{ $t('label.readPrivilege') }}</th>
          <th class="col-weight-16">{{ $t('label.writePrivilege') }}</th>
          <th v-table-action>{{ $t('label.action') }}</th>
        </tr>
        </thead>
        <n-draggable v-model="records" :disabled="!sortable" @on-change="doMenuDrop">
          <tr v-for="(menu, index) in records" :key="index">
            <td colspan="8" style="padding: 0;">
              <table class="table text-center n-table table-menu">
                <tr class="table-menu-tr" :style="{ cursor: sortable ? 'move' : '' }">
                  <td class="col-weight-5">
                    <i :class="`menu-expand ${ menu.expand ? 'fas fa-angle-down' : 'fas fa-angle-right' }`" @click="changeExpand(index)"></i>
                  </td>
                  <td class="col-weight-8">{{ menu.id }}</td>
                  <td class="col-weight-8"><i :class="menu.icon"></i></td>
                  <td class="col-weight-19">{{ menu.name }}</td>
                  <td class="col-weight-19">{{ menu.url }}</td>
                  <td class="col-weight-16">-</td>
                  <td class="col-weight-16">-</td>
                  <td v-table-action class="n-button-box">
                    <nb-modal @on-click="openModal(menu)" />
                    <nb-confirm text="remove" disabled />
                  </td>
                </tr>
              </table>
              <table v-if="menu.expand" class="table text-center n-table table-sub-menu">
                <n-draggable v-model="menu.subs" :disabled="!sortable" @on-change="v => doSubMenuDrop(v, index)">
                  <tr v-for="(subMenu, subIndex) in menu.subs" :key="subIndex" class="table-sub-menu-tr" :style="{ cursor: sortable ? 'move' : '' }">
                    <td class="col-weight-5"></td>
                    <td class="col-weight-8">{{ subMenu.id }}</td>
                    <td class="col-weight-8">-</td>
                    <td class="col-weight-19">{{ subMenu.name }}</td>
                    <td class="col-weight-19">{{ subMenu.url }}</td>
                    <td class="col-weight-16">{{ subMenu.rpName }}</td>
                    <td class="col-weight-16">{{ subMenu.wpName }}</td>
                    <td v-table-action class="n-button-box">
                      <nb-modal @on-click="openModal(subMenu)" />
                      <nb-confirm text="remove" @on-ok="remove(subMenu)" />
                    </td>
                  </tr>
                </n-draggable>
              </table>
            </td>
          </tr>
        </n-draggable>
      </table>
    </div>
  </index-content>
</template>

<script>
import OperatorView from './view';
import ModalMenu from './ModalMenu';
import { del, fetch, privileges, sort } from '@/api/operator/operator-menu';
import _ from 'lodash-es';

export default OperatorView.extend({
  name: 'OperatorMenu',
  data() {
    return {
      preload: true,
      sortable: false,
      allExpand: false,
    };
  },
  methods: {
    doLoad() {
      return fetch();
    },
    parse(rows) {
      this.records = rows;
      _.forEach(this.records, menu => {
        menu.expand = false;
      });
      this.allExpand = _.filter(this.records, f => f.expand).length > 0;
    },
    openModal(model = {}) {
      privileges().then(r => {
        this.createModal(ModalMenu, { model, props: { privilegesOptions: r }, on: this });
      });
    },
    remove(model) {
      return del(model.id, model.version);
    },
    changeAllExpand() {
      this.allExpand = !this.allExpand;
      let _records = [];
      _.forEach(this.records, (menu, index) => {
        menu.expand = this.allExpand;
        _records.push(menu);
      });
      this.records = _records;
    },
    changeExpand(index) {
      let menu = this.records[index];
      menu.expand = !menu.expand;
      this.$set(this.records, index, menu);
      this.allExpand = this.records.filter(f => f.expand).length > 0;
    },
    doMenuDrop(menus) {
      let menuIds = [];
      _.forEach(menus, menu => {
        menuIds.push(menu.id);
        _.forEach(menu.subs, subMenu => {
          menuIds.push(subMenu.id);
        });
      });
      sort(menuIds).finally(() => {
        window.location.reload();
      });
    },
    doSubMenuDrop(subMenus, index) {
      let menuIds = [];
      _.forEach(this.records, (menu, i) => {
        menuIds.push(menu.id);
        if (index === i) {
          _.forEach(subMenus, subMenu => {
            menuIds.push(subMenu.id);
          });
        } else {
          _.forEach(menu.subs, subMenu => {
            menuIds.push(subMenu.id);
          });
        }
      });
      sort(menuIds).finally(() => {
        window.location.reload();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.table-responsive > .n-table {
  min-width: 1200px;

  .all-expand,
  .menu-expand {
    cursor: pointer;
  }

  .n-table.table-menu {
    margin-top: -1px;
    margin-bottom: 0;

    .table-menu-tr {
      background-color: #f8f8f9;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .n-table.table-sub-menu {
    margin-top: -1px;
    margin-bottom: 0;

    .table-sub-menu-tr {
      background-color: #ffffff;

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  .rw-action {
    width: 9rem;
  }

  .ro-action {
    width: $action-detail;
  }
}
</style>
